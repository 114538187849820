<!--
 * @Author: your name
 * @Date: 2021-12-09 11:10:27
 * @LastEditTime: 2021-12-27 10:20:31
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\cq101\page2-2.vue
-->
<template>
  <ul class="content-div cq101-page22">
    <li v-for="item in arr" :key="item.title">
      <span class="l-span">{{ item.title }}</span>
      <span class="d-span">{{ item.desc }}</span>
    </li>
  </ul>
</template>

<script>
export default {
  name: "",
  data() {
    return {
      arr: [
        {
          title: "简约耐用",
          desc: "自成风景",
        },
        {
          title: "微电脑",
          desc: "触控设置控制参数",
        },
        {
          title: "智能物联",
          desc: "物联网平台远程控制",
        },
        {
          title: "智能转仓",
          desc: "是测报仪也是捕虫器",
        },
        {
          title: "监测数据精准",
          desc: "超清摄像头搭配超高识别率AI算法",
        },
      ],
    };
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.content-div {
  display: flex;
  flex-wrap: wrap;
  width: 850px;
  margin: auto;
  li {
    width: 48%;
    display: flex;
    flex-direction: column;
    margin-top: 110px;
    &:nth-child(2n) {
      margin-left: 4%;
    }
    .l-span {
      font-size: 60px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #1dbf73;
      line-height: 48px;
    }
    .d-span {
      font-size: 22px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #ffffff;
      line-height: 48px;
      opacity: 0.6;
      margin-top: 8px;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .content-div {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    margin: auto;
    padding: 0 7%;
    li {
      margin-top: 1rem;
      &:nth-child(2n) {
        margin-left: 4%;
      }
      .l-span {
        font-size: 0.55rem;
        line-height: 0.44rem;
        white-space: nowrap;
      }
      .d-span {
        font-size: 0.2rem;
        line-height: 0.44rem;
      }
    }
  }
}
</style>
