<!--
 * @Author: your name
 * @Date: 2021-12-04 13:55:18
 * @LastEditTime: 2021-12-30 18:56:54
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\cq101\page2.vue
-->
<template>
  <div class="cq101-page2">
    <div class="text-div">
      <ctitle titleb="虫情诊断新起点"></ctitle>
      <div class="d-div">
        <p class="desc-p f-desc">
          ST-CQ101虫情测报仪独有转筒式通道切换功能，高精度完成切换模式；
        </p>
        <p class="desc-p">轻松完成自主杀虫、残骸称重、自动倾倒三步；</p>
        <p class="desc-p">虫体完整率达到新高度；</p>
        <p class="desc-p">
          搭配精准AI识别，配合上太云物联平台，科学分析虫害详情
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1.vue";
export default {
  name: "",
  components: { ctitle },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.cq101-page2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 30px;
  .text-div {
    display: flex;
    flex-direction: column;
    align-items: center;
    .desc-p {
      text-align: center;
      font-size: 24px;
      font-family: PingFang SC;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      margin: 0;
      line-height: 40px;
      &.f-desc {
        margin-top: 26px;
      }
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .cq101-page2 {
    .text-div {
      .d-div {
        padding: 0 7%;
        line-height: 0.44rem;
        margin-top: 0.34rem;
        .desc-p {
          font-size: 0.27rem;
          display: inline;
          line-height: 0.22rem;
        }
      }
    }
  }
}
</style>
