<!--
 * @Author: your name
 * @Date: 2021-12-04 14:32:10
 * @LastEditTime: 2021-12-27 10:20:01
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\cq101\page4.vue
-->
<template>
  <div class="cq101-page4 pt80">
    <div class="text-div">
      <ctitle class="w-title" titleb="强大远程分析 , 智能简单操作"></ctitle>
      <div class="m-title">
        <span>强大远程分析</span>
        <span>智能简单操作</span>
      </div>
      <p class="d-p" style="margin-top: 40px">
        平台内置超高识别率的AI算法程序、自动分类、计数、预警；借助上太云物联平台，云端作业。
      </p>
      <p class="d-p">
        4G通讯，数据实时传输；适配各类移动设备，垂直触达数据，在哪都能精准监控，轻松入门数字农业。
      </p>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/cq101/2.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
import ctitle from "@/components/sTitle/title1";
export default {
  name: "",
  components: {
    ctitle,
  },
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.cq101-page4 {
  position: relative;
  height: 100vh;
  overflow: hidden;
  .text-div {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 20px;
    .m-title {
      display: none;
    }
    .d-p {
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 40px;
      margin: 0;
    }
  }
  .img-div {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .cq101-page4 {
    height: auto;
    padding-top: 0.36rem;
    padding-bottom: 50vh;
    .text-div {
      .m-title {
        display: block;
        font-size: 0.55rem;
        color: #ffffff;
        display: flex;
        flex-direction: column;
        align-content: center;
        font-weight: 600;
      }
      .w-title {
        display: none;
      }
      .d-p {
        font-size: 0.27rem;
        line-height: 0.36rem;
        padding: 0 7%;
      }
    }
  }
}
</style>
