<!--
 * @Author: your name
 * @Date: 2021-12-04 13:54:19
 * @LastEditTime: 2021-12-23 10:56:46
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\cq101\page1.vue
-->
<template>
  <div class="cq101-page1 pt80">
    <div class="text-div">
      <span class="l-span din">ST-CQ101</span>
      <span class="l2-span">智能虫情测报仪</span>
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/cq101/1.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.cq101-page1 {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  .text-div {
    padding-top: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .l-span {
      font-size: 84px;
      font-weight: 500;
      color: #ffffff;
    }
    .l2-span {
      font-size: 36px;
      font-family: Microsoft YaHei;
      font-weight: bold;
      color: #ffffff;
    }
  }
  .img-div {
    margin-top: 60px;
  }
}
@media screen and (max-width: 1920px) {
  .img-div {
    width: 63.14%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .cq101-page1 {
    height: auto;
    .text-div {
      width: 100%;
      .l-span {
        font-size: 0.73rem;
      }

      .l2-span {
        font-size: 0.33rem;
      }
    }
    .img-div {
      display: flex;
      justify-content: center;
      width: 80%;
      img {
        width: 100%;
      }
    }
  }
}
</style>
