<!--
 * @Author: your name
 * @Date: 2021-12-04 15:12:35
 * @LastEditTime: 2021-12-27 10:20:17
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\cq101\page6.vue
-->
<template>
  <div class="cq101-page pt80">
    <div class="title-div">
      <span class="t-span">省心作业三部曲</span>
      <span class="s-span">
        <span>自主杀虫</span>
        <span class="f-span">，</span>
        <span>残骸称重</span>
        <span class="f-span">，</span>
        <span>自动清倒</span>
      </span>
      <span class="d-span"
        >虫量巨大时完全自主利用负压风机吸积害虫；独有残骸称重功能，搭载红外矩阵助力虫体计数，提供更多数据更多维度精准分析；在满仓后自动清倒，自动快速恢复杀虫功能。</span
      >
    </div>
    <div class="img-div">
      <img
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/cq101/5.png"
        alt="上太科技"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.cq101-page {
  height: 100vh;
  position: relative;
  overflow: hidden;
  margin-top: 20px;
  .title-div {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    .t-span {
      font-size: 60px;
      font-family: MicrosoftYaHeiLight;
      font-weight: 400;
      color: #ffffff;
      line-height: 80px;
    }
    .s-span {
      font-size: 60px;
      font-family: MicrosoftYaHeiLight;
      font-weight: 600;
      color: #1dbf73;
      line-height: 80px;
    }
    .d-span {
      width: 1008px;
      font-size: 24px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: rgba($color: #fff, $alpha: 0.6);
      line-height: 48px;
      margin-top: 16px;
    }
  }
  .img-div {
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .cq101-page {
    height: 100vh;
    .title-div {
      .t-span {
        font-size: 0.55rem;
        line-height: 0.73rem;
      }
      .s-span {
        font-size: 0.73rem;
        line-height: 0.73rem;
        display: flex;
        flex-direction: column;
        span {
          margin-top: 5px;
        }
        .f-span {
          display: none;
        }
      }
      .d-span {
        font-size: 0.22rem;
        line-height: 0.44rem;
        margin-top: 0.15rem;
        white-space: normal;
        width: 100%;
        padding: 0 7%;
      }
    }
    .img-div {
      img {
        height: 100vh;
        object-fit: cover;
      }
    }
  }
}
</style>
