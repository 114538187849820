<!--
 * @Author: your name
 * @Date: 2021-12-04 14:13:52
 * @LastEditTime: 2021-12-23 11:15:39
 * @LastEditors: Please set LastEditors
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: \new-website-1123\src\views\special\cq101\page3.vue
-->
<template>
  <div class="cq101-page3 pt80">
    <div class="img-div">
      <img
        class="data-img"
        src="https://stkj-website-file.oss-cn-chengdu.aliyuncs.com/resources/special/cq101/1.png"
        alt="上太科技"
      />
      <div class="filter-div"></div>
    </div>
    <div class="text-div">
      <span>简约而不简单</span>
      <span>是测报仪也是风景</span>
    </div>
    <div class="desc-div">
      <span>象牙白搭配生命绿，外观简约自然，</span>
      <span>整机结构材质采用304不锈钢静电粉末喷涂处理，坚固耐用；</span>
      <span>拥有超过10年寿命，凸显超高性价比</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "",
  data() {
    return {};
  },
  created() {},
  methods: {},
};
</script>

<style lang='scss' scoped>
.cq101-page3 {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  overflow: hidden;
  .img-div {
    margin-top: 10px;
    position: relative;
  }
  .filter-div {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba($color: #161616, $alpha: 0.7);
    background-image: radial-gradient(
      rgba($color: #161616, $alpha: 0.7),
      rgba($color: #161616, $alpha: 1)
    );
    opacity: 0;
  }
  .desc-div,
  .text-div {
    opacity: 0;
    position: absolute;
    top: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .text-div {
    font-size: 100px;
    font-family: Microsoft YaHei;
    font-weight: bold;
    color: #1dbf73;
    line-height: 130px;
    top: 25%;
    transform: translateY(100px);
  }
  .desc-div {
    font-size: 24px;
    font-family: PingFang SC;
    font-weight: 400;
    color: rgba($color: #fff, $alpha: 0.6);
    line-height: 40px;
    transform: translateY(-100px);
    top: calc(25% + 300px);
  }
}

@media screen and (max-width: 1920px) {
  .img-div {
    width: 63.14%;
    img {
      width: 100%;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: $mobile-width) {
  .cq101-page3 {
    height: auto;

    .filter-div,
    .desc-div,
    .text-div {
      opacity: 1;
    }

    .text-div {
      font-size: 0.73rem;
      line-height: 1rem;
      top: 25%;
      transform: translateY(0);
    }
    .desc-div {
      font-size: 0.22rem;
      line-height: 0.36rem;
      top: calc(60%);
      transform: translateY(0);
    }
    .img-div {
      width: 100%;
      img {
        width: 100%;
        object-fit: contain;
      }
    }
  }
}
</style>
