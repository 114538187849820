<!--
 * @Author: your name
 * @Date: 2021-12-04 13:48:55
 * @LastEditTime: 2022-01-26 16:33:51
 * @LastEditors: Please set LastEditors
 * @Description: 虫情测报
 * @FilePath: \new-website-1123\src\views\special\PestForecasting\index.vue
-->
<template>
  <div class="cq101-page">
    <page1></page1>
    <page2 class="opacity-page"></page2>
    <page22 class="opacity-page"></page22>
    <!-- 空div用于计算高度 -->
    <div></div>
    <div class="sticky-box-3">
      <div class="sticky-child">
        <page3></page3>
      </div>
    </div>
    <page4 class="opacity-page scale-page"></page4>
    <!-- 空div用于计算高度 -->
    <div></div>
    <div class="sticky-box-5">
      <div class="sticky-child">
        <page5></page5>
      </div>
    </div>
    <page6 class="opacity-page"></page6>
  </div>
</template>

<script>
import page1 from "./page1.vue";
import page2 from "./page2.vue";
import page22 from "./page2-2.vue";
import page3 from "./page3.vue";
import page4 from "./page4.vue";
import page5 from "./page5.vue";
import page6 from "./page6.vue";
import {getNesViewApi} from '@/api/index.js'
export default {
  name: "cq101-page",
  components: {
    page1,
    page2,
    page22,
    page3,
    page4,
    page5,
    page6,
  },
  data() {
    return {
      scrollTop: 0,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.cscroll, false);
    this.addView()
  },
  beforeDestroy() {
    window.removeEventListener("scroll", this.cscroll, false);
  },
  methods: {
    addView(){
      getNesViewApi({type:1,pid:"bcbc814ab96d41a19c27a77764a04adc"})
    },
    /**
     * @description: 监听滚动
     * @param {*}
     * @return {*}
     */
    cscroll() {
      let html = document.querySelector("html");
      this.scrollTop = html.scrollTop;
      if (window.innerWidth > 800) {
        this.page3s();
        this.page5s();
      }
    },
    /**
     * @description: page5 的滚动监听
     */
    page5s() {
      let box5 = document.querySelector(".sticky-box-5");
      // let wHeight = window.innerHeight;
      // 固定div的上一个兄弟节点, 用户计算相对位置
      let prev = box5.previousElementSibling;
      let div = document.querySelector(".cq101-page5");
      let imgDiv = div.querySelector(".imgs-div");

      let cImg = imgDiv.querySelector(".c-img");
      let descT = imgDiv.querySelectorAll(".desc-t");
      let pT = imgDiv.querySelectorAll(".desc-t p");

      let dImg = imgDiv.querySelector(".d-img");
      let desc3 = imgDiv.querySelector(".desc-3");
      let p3 = imgDiv.querySelector(".desc-3 p");

      let c1Div = div.querySelector(".c1-div");
      let c2Div = div.querySelector(".c2-div");
      let cTop = prev.offsetTop - this.scrollTop;
      if (cTop < -5800) {
        let top = cTop + 5800;
        let rate = Math.abs(top / 500);
        c2Div.style.opacity = rate;
        p3.style.height = 30 + "px";
      } else if (cTop < -5500) {
        let top = cTop + 5500;
        let rate = Math.abs((top / 300) * 30);
        c2Div.style.opacity = 0;
        p3.style.height = rate + "px";
        desc3.style.width = 200 + "px";
      } else if (cTop < -5000) {
        let top = cTop + 5000;
        let rate = Math.abs(top / 500);
        dImg.style.opacity = 1;
        desc3.style.width = rate * 200 + "px";
        p3.style.height = 0;
      } else if (cTop < -4700) {
        let top = cTop + 4700;
        let rate = Math.abs(top / 300);
        dImg.style.opacity = rate;
        desc3.style.width = "0px";
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 0;
        }
      } else if (cTop < -4100) {
        let top = cTop + 4100;
        let name = Math.abs(parseInt((top / 600) * 51));
        let img = imgDiv.querySelectorAll(`.data-img`);
        for (let i = 0; i < img.length; i++) {
          const element = img[i];
          element.style.opacity = "0";
        }
        img[name].style.opacity = "1";
        cImg.style.opacity = 0;
        dImg.style.opacity = 0;
      } else if (cTop < -3900) {
        let top = cTop + 3900;
        let rate = Math.abs(top / 300);
        cImg.style.opacity = 1 - rate;
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 0;
        }
      } else if (cTop < -3400) {
        let top = cTop + 3400;
        let rate = Math.abs(top / 500);
        rate = 1 - Math.min(rate, 1);
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = rate * 200 + "px";
        }
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 0;
        }
        cImg.style.opacity = 1;
      } else if (cTop < -3100) {
        let top = cTop + 3100;
        let rate = Math.abs((top / 300) * 30);
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 30 - rate + "px";
        }
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 200 + "px";
        }
        c1Div.style.opacity = 0;
      } else if (cTop < -2600) {
        let top = cTop + 2600;
        let rate = Math.abs(top / 500);
        c1Div.style.opacity = 1 - rate;
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 30 + "px";
        }
      } else if (cTop < -1600) {
        let top = cTop + 1600;
        let rate = Math.abs(top / 500);
        c1Div.style.opacity = rate;
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 30 + "px";
        }
      } else if (cTop < -1300) {
        c1Div.style.opacity = 0;
        let top = cTop + 1300;
        let rate = Math.abs((top / 300) * 30);
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = rate + "px";
        }
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 200 + "px";
        }
      } else if (cTop < -800) {
        let top = cTop + 800;
        let rate = Math.abs(top / 500);
        rate = Math.min(rate, 1);
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = rate * 200 + "px";
        }
        for (let i = 0; i < pT.length; i++) {
          const element = pT[i];
          element.style.height = 0;
        }
        cImg.style.opacity = 1;
      } else if (cTop < -300) {
        let top = cTop + 300;
        let rate = Math.abs(top / 300);
        cImg.style.opacity = rate;
        for (let i = 0; i < descT.length; i++) {
          const element = descT[i];
          element.style.width = 0;
        }
      } else if (cTop < 0) {
        cImg.style.opacity = 0;
      }
    },
    /**
     * @description: page3 的滚动监听
     */
    page3s() {
      // 固定div
      let box3 = document.querySelector(".sticky-box-3");
      // let wHeight = window.innerHeight;
      // 固定div的上一个兄弟节点, 用户计算相对位置
      let prev = box3.previousElementSibling;
      let div = document.querySelector(".cq101-page3");
      let cTop = prev.offsetTop - this.scrollTop;
      // 遮罩层
      let filterBox = div.querySelector(".filter-div");
      let text = div.querySelector(".text-div");
      let desc = div.querySelector(".desc-div");
      if (cTop < -1000) {
        desc.style.opacity = 1;
        desc.style.transform = `translateY(0px)`;
      } else if (cTop < -800) {
        let tTop = cTop + 800;
        let oTop = (tTop / 200) * 100;
        let x = 100 - Math.abs(oTop);
        let rate = Math.abs(tTop / 300);
        text.style.transform = `translateY(${x}px)`;
        desc.style.transform = `translateY(-${x}px)`;
        desc.style.opacity = rate;
        text.style.opacity = 1;
      } else if (cTop < -500) {
        let tTop = cTop + 500;
        let rate = Math.abs(tTop / 300);
        text.style.opacity = rate;
        desc.style.opacity = 0;
      } else if (cTop < 0) {
        let rate = Math.abs(cTop / 500);
        filterBox.style.opacity = rate;
        text.style.opacity = 0;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.cq101-page {
  background-color: #161616;
  .sticky-box-3 {
    height: calc(100vh + 1300px);
    .sticky-child {
      position: sticky;
      top: 0;
    }
  }
  .sticky-box-5 {
    height: calc(100vh + 6600px);
    .sticky-child {
      position: sticky;
      top: 0;
    }
  }
}
@media screen and (max-width: $mobile-width) {
  .cq101-page {
    background-color: #161616;
    .sticky-box-3 {
      height: auto;
      .sticky-child {
        position: relative;
        top: 0;
      }
    }
    .sticky-box-5 {
      height: auto;
      .sticky-child {
        position: relative;
        top: 0;
      }
    }
  }
}
</style>
